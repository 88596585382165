@font-face {
  font-family: "Sundays";
  src: url("https://static1.squarespace.com/static/5fb59af39d231030c64138f7/t/5fb6e01e9bd750165985c2a0/1605820446241/SUNDAYS.otfhttps://static1.squarespace.com/static/5fb59af39d231030c64138f7/t/5fb6e01e9bd750165985c2a0/1605820446241/SUNDAYS.otfhttps://static1.squarespace.com/static/5fb59af39d231030c64138f7/t/5fb6e01e9bd750165985c2a0/1605820446241/SUNDAYS.otf")
      format("otf"),
    url("https://static1.squarespace.com/static/5fb59af39d231030c64138f7/t/5fb6e013c04c344b331f6be2/1605820435130/SUNDAYSRegular.woff2")
      format("woff");
}
h1 {
  font-family: "Sundays" !important;
}
h2 {
  font-family: "Sundays" !important;
}
@font-face {
  font-family: Jules;
  src: url("https://static1.squarespace.com/static/5fb59af39d231030c64138f7/t/5fbab4049876b8736cd08f19/1606071300591/Jules-Regular.otf");
}
.title {
  text-align: center;
  color: rgb(43, 48, 54);
  font-family: "Sundays";
  font-size: 7vh;

  margin-top: 50px;
}
.icon {
  text-align: center;
  color: rgb(102, 102, 102);
  font-family: "Sundays";
  font-size: 18px;
}
.sc {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-align: left !important;
  letter-spacing: 0em;
  text-transform: none;
  font-family: Lato;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 2.4px;
  line-height: 1.2em;
  text-transform: uppercase;
}
.price {
  color: #413366d7;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 7vh;
}
.service {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.title-md {
  text-align: left;
  color: rgb(20, 22, 24);
  font-family: "Sundays";
  font-size: 5vh;
  text-shadow: #1e242b --3px;
  margin-top: 5px;
}
.footer {
  background-color: rgb(71, 73, 77);
  color: aliceblue;
  text-align: center;
  font-size: 14px;
  margin: 0px;
  margin-top: 100px;
  padding: 50px 10px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.nav-text {
  margin-left: 15px;
  margin-right: 10px;
  text-align: center;
  color: rgb(17, 12, 44);
  text-shadow: 1px 1px #100c24;
  font-family: "Sundays";
  font-size: 3vh;
}
.box {
  border: 1px solid;
  padding: 10px;
  box-shadow: 2px 2px #93a2b1;
}
.title-small {
  text-align: center;
  color: rgb(2, 2, 2);
  font-family: Jules;
  src: url("https://static1.squarespace.com/static/5fb59af39d231030c64138f7/t/5fbab4049876b8736cd08f19/1606071300591/Jules-Regular.otf");
  margin-top: 2vh;
}

h3 {
  text-align: center;
  color: rgb(43, 48, 54);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  text-shadow: #343a40 --2px;
}
.h50 {
  height: 50px;
}
.h30 {
  height: 30px;
}
.h10 {
  height: 10px;
}
.quotetable {
  text-align: left;
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-shadow: #343a40 --2px;
  height: 100;
}
.logo {
  height: 32px;
  width: 32px;
}
.date {
  padding: "10px";
}
.table_history {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: 300px;
  width: 100%;
}
.table_stock {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: 600px;
  width: 100%;
  color: #000000e0;
}
.context {
  color: rgb(89, 95, 116);
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 2vh;
  text-align: center;
  line-height: 200%;
  padding: 1em;
}
.staff {
  border: 1px solid;
}
.content-s {
  font-family: "Courier New", Courier, monospace;
  font-size: medium;
  text-align: left;
  margin-bottom: 15px;
  line-height: 150%;
  color: rgb(5, 11, 27);
}
.context-s {
  font-family: "Courier New", Courier, monospace;
  font-size: medium;
  text-align: left;
  margin-bottom: 15px;
  line-height: 200%;
}
#btnGroupAddon {
  background-color: #000000e0;
  color: aliceblue;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.margin {
  margin: 10px;
}
.width {
  width: 300px;
}
.left-15 {
  margin-right: 50px;
}

.textleft {
  text-align: left;
}
/* .carousel-caption {
  height: 40vh;
  top: 10%;
  position: absolute;
  right: 15%;
  bottom: 0;
  left: 15%;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: center;
}*/
.Cards {
  justify-items: auto;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.carousel-caption h1 {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* .carousel-item {
  height: 70vh;
} */

/* @media only screen and (max-width: 600px) {
  .carousel-item {
    height: 90vh;
  }
} */
/* 
.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
} */
/* .carousel-inner {
  max-height: 70vh !important;
} */

/* .heigth {
  display: flex !important;
  justify-content: center;
  align-items: center;
} */

#block-10753cf31d9f0e038e75 {
  background: #fff;
  opacity: 0.75;
  padding: 40px;
  text-align: center;
}

.sqs-block-quote {
  font-family: "Jules" !important;
}
blockquote {
  color: #0e0e47;
  font-size: 50px;
  font-family: "Jules" !important;
  font-weight: bold;
}
.newsletter-block .newsletter-form-field-element {
  background-color: transparent !important;
  border: 0px solid;
  border-bottom: 1px solid #000;
}
.newsletter-block .newsletter-form-field-element {
  font-family: Lato;
}
.newsletter-form-button-label {
  font-family: Sundays;
  text-transform: none;
  letter-spacing: 0.2em;
}
.newsletter-block .newsletter-form-button {
  color: #fff;
  background-color: #000;
  border: 1px solid #000 !important;
}
.sqs-block-markdown h3 {
  border-bottom: 1px solid #e7e4df;
  padding: 14px;
}
.sqs-block-markdown h3 {
  color: #000;
}
.Mobile-bar--top {
  padding-top: 50px !important;
  padding-bottom: 0px !important;
}
